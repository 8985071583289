import React from 'react'
import { Configure } from 'react-instantsearch-dom'
import { config } from '../../config'

export function Filters({ negotiation, values }) {
  const minConditionIndex = config.conditions.indexOf(values.condition)
  const minBedrooms = [0, 1, 2, 3].indexOf(parseInt(values.bedrooms))

  return (
    <Configure
      query={values.location}
      facetFilters={[
        // negotiation can be null in
        // the case of projects
        negotiation && `negotiation:${negotiation}`,
        values.types.map((type) => `type:${type}`),
        values.wishes.map((wish) => `attributes.amenities:${wish}`),
        config.conditions
          .slice(minConditionIndex)
          .map((condition) => `attributes.general_condition:${condition}`),
        values.internalTypes?.map(
          (internalType) => `internal_type:${internalType}`
        ),
      ].filter(Boolean)}
      filters={[
        minBedrooms && `attributes.structure.bedrooms >= ${minBedrooms}`,
        values.min_price &&
          `attributes.price.published_price.amount >= ${parseFloat(
            values.min_price
          )}`,
        values.max_price &&
          `attributes.price.published_price.amount <= ${parseFloat(
            values.max_price
          )}`,
      ]
        .filter(Boolean)
        .join(' AND ')}
    />
  )
}
