import React from 'react'
import keyBy from 'lodash/keyBy'
import { FormattedMessage } from 'react-intl'
import SearchMap from './map'
import SearchForm from './search-form'
import Layout from '../split-layout'
import {
  InstantSearch,
  connectInfiniteHits,
  connectStateResults,
  SearchBox,
} from 'react-instantsearch-dom'
import PropertyCard from '../property-card'
import { useAgency } from '../../hooks/use-agency'
import { FormattedHTMLMessage } from 'react-intl'
import algoliasearch from 'algoliasearch/lite'
import { LoadingIndicator } from '../loading'
import { FiltersModal } from './filters-modal'
import { MapModal } from './map-modal'
import { navigate } from 'gatsby'
import { config } from '../../config'
import { Filters } from './filters'
import LocalizedLink from '../localized-link'

const agencyId = process.env.GATSBY_AGENCY_ID

const isSSR = typeof window === 'undefined'

const PropertiesNoResult = () => {
  return (
    <div className="c-row c-row--beta" id="propertiesNoResults">
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <div className="c-alert-box u-text-center">
            <p>
              <FormattedHTMLMessage id="properties.no-results.line_1" />
              <br />
              <FormattedMessage
                id="properties.no-results.line_2"
                values={{
                  link: (
                    <LocalizedLink
                      to="/personal-updates"
                      className="c-alert-box__link"
                    >
                      <FormattedMessage id="properties.no-results.link" />
                    </LocalizedLink>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

function Search({ negotiation, properties, internalTypes, ...props }) {
  const { agency, settings } = useAgency()
  const locale = props.pageContext.locale ?? agency.locale

  const filteredProperties = properties.filter((el) => el.publish_location)

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${agencyId}:properties`}
      root={{ Root: React.Fragment }}
    >
      <Layout
        {...props}
        left={
          <LeftSection
            properties={filteredProperties}
            locale={locale}
            settings={settings}
          />
        }
        right={
          <RightSection
            location={props.location}
            negotiation={negotiation}
            internalTypes={internalTypes}
            locale={locale}
            properties={properties}
            settings={settings}
          />
        }
        title={
          negotiation
            ? negotiation === 'let'
              ? 'page.to-let'
              : 'page.for-sale'
            : 'page.projects'
        }
      />
    </InstantSearch>
  )
}

function LeftSection({ properties, locale, settings }) {
  return (
    <SearchMap
      className="c-properties__map u-hide-bp3-min"
      properties={properties}
      agencySettings={settings}
      locale={locale}
    />
  )
}

function RightSection({ location, negotiation, ...props }) {
  const { currencies } = useAgency()

  const filters = location.state?.filters ?? {
    condition: 'poor',
    types: config.propertyTypes,
    bedrooms: 0,
    location: '',
    wishes: [],
    min_price: 0,
    max_price: '',
    internalTypes: props.internalTypes,
    currency: currencies[0],
    negotiation,
  }

  const setFilters = (filters) => {
    navigate(location.pathname, {
      replace: true,
      state: {
        filters,
      },
    })
  }

  const propertiesById = React.useMemo(() => keyBy(props.properties, 'id'), [
    props.properties,
  ])

  return (
    <div>
      <Filters values={filters} negotiation={negotiation} />
      <SearchForm
        negotiation={negotiation}
        currencies={currencies}
        filters={filters}
        onSetFilters={setFilters}
      />
      <MobileActions
        filters={filters}
        onSetFilters={setFilters}
        locale={props.locale}
        properties={props.properties}
        agencySettings={props.settings}
      />
      <div className="c-row c-row--beta">
        <InfiniteHits propertiesById={propertiesById} />
        <LoadingState />
      </div>
    </div>
  )
}

export default Search

const LoadingState = connectStateResults(
  ({ searching, searchResults, isSearchStalled, ...props }) => {
    const [initialLoad, setInitialLoad] = React.useState(true)

    React.useEffect(() => {
      // this gets called only on the client
      // so we get the same initial status on SSR
      // and after first hydratation
      setInitialLoad(false)
    }, [])

    if (initialLoad) {
      return <LoadingIndicator />
    }

    const hasResults =
      searchResults && searchResults.hits && searchResults.hits.length > 0

    return (
      <>
        {!hasResults && !isSearchStalled && !isSSR && <PropertiesNoResult />}
        {isSearchStalled && <LoadingIndicator />}
      </>
    )
  }
)

const InfiniteHits = connectInfiniteHits(
  ({ hits, hasMore, refineNext, propertiesById }) => {
    return (
      <>
        <div className="u-css-grid u-grid-cols-1-of-2-bp4 u-grid-cols-1-of-3-bp6 u-grid-gap">
          {hits.map((hit) => {
            const property = propertiesById[hit.objectID]
            if (!property) {
              console.warn('no property with id ' + hit.objectID + ' found')
              console.warn(JSON.stringify(propertiesById))
              console.warn(JSON.stringify(hit))
              return null
            }
            return (
              <Hit
                hit={hit}
                key={hit.objectID}
                property={propertiesById[hit.objectID]}
              />
            )
          })}
        </div>
        {hasMore && (
          <div className="c-row c-row--beta">
            <div className="o-grid o-grid--gutter">
              <nav>
                <a
                  className="c-button c-button--beta c-button--block c-button--md"
                  onClick={(evt) => {
                    evt.preventDefault()
                    refineNext()
                  }}
                >
                  <FormattedMessage id="more-results" />
                </a>
              </nav>
            </div>
          </div>
        )}
      </>
    )
  }
)

const Hit = (props) => {
  return <PropertyCard property={props.property} />
}

function MobileActions({
  filters,
  onSetFilters,
  locale,
  properties,
  agencySettings,
}) {
  const [showFilters, setShowFilters] = React.useState(false)
  const [showMap, setShowMap] = React.useState(false)

  return (
    <div className="c-row c-row--beta u-pb-alpha u-hide-bp3">
      <div className="u-hide-bp3" style={{ position: 'relative' }}>
        <FormattedMessage id="search_location_placeholder">
          {(placeholder) => (
            <SearchBox
              defaultRefinement={filters.location}
              translations={{ placeholder }}
            />
          )}
        </FormattedMessage>
        <div
          className="toolbar u-mt-alpha u-mb-alpha"
          style={{ marginTop: 10 }}
        >
          <button
            className="c-button c-button--alpha c-button--md c-button--half"
            onClick={(evt) => {
              evt.preventDefault()
              setShowFilters(true)
            }}
          >
            <FormattedMessage id="edit-filters" />
          </button>
          <FiltersModal
            filters={filters}
            onSetFilters={(filters) => {
              setShowFilters(false)
              onSetFilters(filters)
            }}
            isOpen={showFilters}
            onClose={() => setShowFilters(false)}
          />
          <button
            type="button"
            className="c-button c-button--neutral c-button--md  c-button--half"
            onClick={(evt) => {
              evt.preventDefault()
              setShowMap(true)
            }}
          >
            <FormattedMessage id="view-on-map" />
          </button>
          <MapModal
            isOpen={showMap}
            onClose={() => setShowMap(false)}
            {...{ locale, properties, agencySettings }}
          />
        </div>
      </div>
    </div>
  )
}
