import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from '../modal'
import Map from './map'

export function MapModal({
  isOpen,
  onClose,
  properties,
  locale,
  agencySettings,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="map" />}
    >
      <Map
        style={{ width: '100%', height: '100%' }}
        locale={locale}
        properties={properties}
        agencySettings={agencySettings}
      />
      <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
        <div className="c-button-fixed">
          <button
            type="button"
            className="c-button c-button--md c-button--alpha c-button--block closeModal"
            onClick={onClose}
          >
            <FormattedMessage id="show-list" />
          </button>
        </div>
      </div>
    </Modal>
  )
}
