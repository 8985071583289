import React from 'react'
import FooterNavigation from './footer-navigation'
import AgencyLocation from './agency-location'
import SocialChannels from './social-channels'
import Contact from './contact-btn'
import H3 from '../../theming/H3'

export default function FooterSearch({ settings, agency, locale }) {
  return (
    <nav className="c-row c-row--beta c-row--footer">
      <div className="o-container">
        <div className="o-grid o-grid--gutter">
          <div className="o-grid__item">
            <H3>{agency.name}</H3>
          </div>
          <div className="o-grid__item u-1-of-2-bp5">
            <FooterNavigation settings={settings} locale={locale} />
          </div>
          <div className="o-grid__item u-1-of-2-bp5">
            <AgencyLocation settings={settings} />
          </div>
          <div className="o-grid__item">
            <Contact />
            <SocialChannels settings={settings} />
          </div>
        </div>
      </div>
    </nav>
  )
}
