import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SearchBox } from 'react-instantsearch-dom'
import classNames from 'classnames'
import { useFormik } from 'formik'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Input from '../../theming/Input'
import H2 from '../../theming/H2'
import { config } from '../../config'

export const PROPERTY_TYPES = [
  'house',
  'apartment',
  'parking',
  'land',
  'office',
  'commercial',
]

export const PROPERTY_BEDROOMS = ['0', '1', '2', '3']

function SearchForm({
  filters,
  onSetFilters,
  currencies,
  negotiation,
  ...props
}) {
  const [showMore, setShowMore] = React.useState(false)

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: filters,
    onSubmit: props.onSubmit,
  })

  useDeepCompareEffect(() => {
    onSetFilters(values)
  }, [values])

  if (!negotiation) {
    // negotiation will be undefined only for projects
    // so we use that fact to hide the filters
    return null
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="c-row c-row--alpha c-row--collapsed">
        <div className="c-properties__filter u-hide-bp3-min">
          <article className="panel-horizontal">
            <div className="flex items-center">
              <H2 className="panel-horizontal__title ">
                <FormattedMessage id="location" />
              </H2>
              <div className="panel-horizontal__body flex-grow ml-5">
                <FormattedMessage id="search_location_placeholder">
                  {(placeholder) => (
                    <SearchBox
                      defaultRefinement={values.location}
                      translations={{ placeholder }}
                    />
                  )}
                </FormattedMessage>
              </div>
            </div>
          </article>
          <article className="panel-horizontal">
            <div className="o-grid o-grid__gutter">
              <H2 className="panel-horizontal__title o-grid__item u-1-of-6-bp4">
                <FormattedMessage id="type" />
              </H2>
              <div className="panel-horizontal__body o-grid__item u-5-of-6-bp4">
                <ul className="o-list">
                  {config.propertyTypes.map((type) => {
                    return (
                      <li key={type} className="checkbox checkbox--inline">
                        <label className="c-label">
                          <input
                            type="checkbox"
                            name="types"
                            value={type}
                            checked={values.types.includes(type)}
                            onChange={handleChange}
                            disabled={
                              values.types.includes(type) &&
                              values.types.length === 1
                            }
                          />
                          <FormattedMessage id={`property.types.${type}`} />
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </article>
          <article className="panel-horizontal">
            <div className="o-grid o-grid__gutter">
              <H2 className="panel-horizontal__title o-grid__item u-1-of-6-bp4">
                <FormattedMessage id="filters.min-bedrooms" />
              </H2>
              <div className="panel-horizontal__body o-grid__item u-5-of-6-bp4">
                <ul className="o-list">
                  {PROPERTY_BEDROOMS.map((minBedroomsCount) => (
                    <li className="radio radio--inline">
                      <label className="c-label">
                        <input
                          type="radio"
                          value={minBedroomsCount}
                          name="bedrooms"
                          onChange={handleChange}
                          checked={
                            parseInt(values.bedrooms) ===
                            parseInt(minBedroomsCount)
                          }
                        />{' '}
                        {parseInt(minBedroomsCount) === 3 ? (
                          <FormattedMessage id={'more'} />
                        ) : (
                          minBedroomsCount
                        )}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </article>
          <article
            className={classNames('panel-horizontal', {
              'is-hidden': !showMore,
            })}
          >
            <div className="o-grid o-grid__gutter">
              <H2 className="panel-horizontal__title o-grid__item u-1-of-6-bp4">
                <FormattedMessage id="budget" />
              </H2>
              <div className="panel-horizontal__body o-grid__item u-5-of-6-bp4">
                {currencies.length > 1 && (
                  <div className="o-grid">
                    <div className="c-input-holder">
                      <div className="o-grid__item u-1-of-6">
                        <label
                          className="c-label c-label--prefix c-label--sm"
                          htmlFor="currency"
                        >
                          <FormattedMessage id="currency" />
                        </label>
                      </div>
                      <div className="o-grid__item u-4-of-6 u-2-of-6-bp4">
                        <select
                          className="c-input-text c-input-text--sm c-input-option currency-select"
                          name="currency"
                          value={values.currency}
                          onChange={handleChange}
                        >
                          {currencies.map((currency) => (
                            <option value={currency}>{currency}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="o-grid">
                  <div className="c-input-holder">
                    <div className="o-grid__item u-1-of-6">
                      <label
                        className="c-label c-label--prefix c-label--sm"
                        htmlFor="price-max"
                      >
                        <FormattedMessage id="max" />
                      </label>
                    </div>
                    <div className="o-grid__item u-4-of-6 u-2-of-6-bp4">
                      <Input
                        type="number"
                        className="c-input-text c-input-text--sm c-input-text--prefix selectOnFocus"
                        id="price-max"
                        name="max_price"
                        min={values.min_price}
                        value={values.max_price}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--sm currency-label">
                        {values.currency}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="o-grid">
                  <div className="c-input-holder">
                    <div className="o-grid__item u-1-of-6">
                      <label
                        className="c-label c-label--prefix c-label--sm"
                        htmlFor="price-min"
                      >
                        <FormattedMessage id="min" />
                      </label>
                    </div>
                    <div className="o-grid__item u-4-of-6 u-2-of-6-bp4">
                      <Input
                        type="number"
                        className="c-input-text c-input-text--sm c-input-text--prefix selectOnFocus"
                        id="price-min"
                        name="min_price"
                        min="0"
                        value={values.min_price}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--sm currency-label">
                        {values.currency}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>

          {showMore && (
            <article className="panel-horizontal ">
              <div className="o-grid o-grid__gutter">
                <H2 className="panel-horizontal__title o-grid__item u-1-of-6-bp4">
                  <FormattedMessage id="condition" />
                </H2>
                <div className="panel-horizontal__body o-grid__item u-5-of-6-bp4">
                  <ul className="o-list">
                    <span className="radio radio--inline radio--poor">
                      <label className="c-label">
                        <input
                          type="radio"
                          name="condition"
                          value="poor"
                          checked={values.condition === 'poor'}
                          onChange={handleChange}
                        />
                        <FormattedMessage id="conditions.poor" />
                      </label>
                    </span>
                    <span className="radio radio--inline radio--fair">
                      <label className="c-label">
                        <input
                          type="radio"
                          name="condition"
                          value="fair"
                          checked={values.condition === 'fair'}
                          onChange={handleChange}
                        />
                        <FormattedMessage id="conditions.fair" />
                      </label>
                    </span>
                    <span className="radio radio--inline radio--good">
                      <label className="c-label">
                        <input
                          type="radio"
                          name="condition"
                          value="good"
                          checked={values.condition === 'good'}
                          onChange={handleChange}
                        />
                        <FormattedMessage id="conditions.good" />
                      </label>
                    </span>
                    <span className="radio radio--inline radio--mint">
                      <label className="c-label">
                        <input
                          type="radio"
                          name="condition"
                          value="mint"
                          checked={values.condition === 'mint'}
                          onChange={handleChange}
                        />
                        <FormattedMessage id="conditions.mint" />
                      </label>
                    </span>
                    <span className="radio radio--inline radio--new">
                      <label className="c-label">
                        <input
                          type="radio"
                          name="condition"
                          value="new"
                          checked={values.condition === 'new'}
                          onChange={handleChange}
                        />
                        <FormattedMessage id="conditions.new" />
                      </label>
                    </span>
                  </ul>
                </div>
              </div>
            </article>
          )}
          {showMore && (
            <article className="panel-horizontal">
              <div className="o-grid o-grid__gutter">
                <H2 className="panel-horizontal__title o-grid__item u-1-of-6-bp4">
                  <FormattedMessage id="extra-wishes" />
                </H2>
                <div className="panel-horizontal__body o-grid__item u-5-of-6-bp4">
                  <ul className="o-list">
                    {config.wishes.map((wish) => {
                      return (
                        <li className="checkbox checkbox--inline">
                          <label className="c-label">
                            <input
                              type="checkbox"
                              name="wishes"
                              value={wish}
                              onChange={handleChange}
                              checked={values.wishes.includes(wish)}
                            />
                            <FormattedMessage id={`wishes.${wish}`} />
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </article>
          )}

          <article className="panel-horizontal">
            <div className="panel-horizontal__title u-text-center">
              <a
                className="c-button--block"
                onClick={(evt) => {
                  evt.preventDefault()
                  setShowMore(!showMore)
                }}
              >
                {!showMore && (
                  <span>
                    + <FormattedMessage id="show-more" />
                  </span>
                )}
                {showMore && (
                  <span>
                    - <FormattedMessage id="less" />
                  </span>
                )}
              </a>
            </div>
          </article>
        </div>
      </div>
    </form>
  )
}

export default SearchForm
