import React from 'react'

export const LoadingIndicator = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="c-row c-row--beta">
      <div className="o-grid o-grid--gutter">
        <div className="o-grid__item">
          <div className="c-alert-box u-text-center">
            <p>
              <span className="pulse-loader">
                <span className="innerring" />
                <span className="innerdot" />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
})
