import React from 'react'
import Header from '../components/header'
import PersonalUpdates from './search-section'
import Breadcrum from '../components/breadcrum'
import FooterSearch from './footer/footer-search'
import { useAgency } from '../hooks/use-agency'
import SEO from './agency-seo'
import '../assets/scss/screen.scss'
import { FormattedMessage } from 'react-intl'
import Footer from './footer/footer'

export default ({
  right,
  location,
  title,
  description,
  left = null,
  pageContext,
}) => {
  const { settings, agency } = useAgency()
  const locale = pageContext.locale ?? agency.locale

  return (
    <div className="c-search">
      <SEO title={title} description={description} />
      <Header
        logoId={agency.photo?.data.photo_file_id}
        siteTitle={agency.name}
        currentPath={location.pathname}
        locale={locale}
      />
      <Breadcrum path={location.pathname} />
      <div className="c-properties">
        {left}
        <div className="c-properties__list">
          {right}
          <PersonalUpdates
            title={<FormattedMessage id="cta.looking-for-something-else" />}
          />
          <FooterSearch settings={settings} agency={agency} locale={locale} />
          <Footer settings={settings} />
        </div>
      </div>
    </div>
  )
}
