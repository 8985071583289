import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFormik } from 'formik'
import { Modal } from '../modal'
import HouseIcon from '../../assets/img/icons/ico-house.svg'
import ApartmentIcon from '../../assets/img/icons/ico-apartment.svg'
import ParkingIcon from '../../assets/img/icons/ico-parking.svg'
import LandIcon from '../../assets/img/icons/ico-land.svg'
import OfficeIcon from '../../assets/img/icons/ico-office.svg'
import CommercialIcon from '../../assets/img/icons/ico-commercial.svg'
import { config } from '../../config'

const TYPES_ICONS = {
  house: <HouseIcon />,
  apartment: <ApartmentIcon />,
  parking: <ParkingIcon />,
  land: <LandIcon />,
  office: <OfficeIcon />,
  commercial: <CommercialIcon />,
}

var conditionsImages = {
  new: '/img/svg/house-new.svg',
  fair: '/img/svg/house-fair.svg',
  good: '/img/svg/house-good.svg',
  mint: '/img/svg/house-mint.svg',
  poor: '/img/svg/house-poor.svg',
}

export function FiltersModal({ isOpen, onClose, filters, onSetFilters }) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage id="filter" />}
    >
      <MobileFiltersForm filters={filters} onSetFilters={onSetFilters} />
    </Modal>
  )
}

function MobileFiltersForm({ filters, onSetFilters }) {
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: filters,
    onSubmit: onSetFilters,
  })

  return (
    <form
      onSubmit={handleSubmit}
      className="filterPropertiesModalForm"
      style={{
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="c-row c-row--beta" style={{ flex: 1, overflowY: 'auto' }}>
        <div className="o-container">
          <div className="o-grid o-grid--gutter">
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="type" />
                </h2>
                <div className="panel__body">
                  <ul className="c-tile-list c-tile-list--grid c-tile-list--bordered">
                    {config.propertyTypes.map((type) => {
                      return (
                        <li className="c-tile-list__tile" key={type}>
                          <label className="c-label">
                            {TYPES_ICONS[type]}
                            <div className="checkbox">
                              <input
                                type="checkbox"
                                name="types"
                                value={type}
                                checked={values.types.includes(type)}
                                disabled={
                                  values.types.includes(type) &&
                                  values.types.length === 1
                                }
                                onChange={handleChange}
                              />
                              <span className="c-tile-list__title">
                                <FormattedMessage
                                  id={`property.types.${type}`}
                                />
                              </span>
                            </div>
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="budget" />
                </h2>
                <div className="panel__body">
                  <div className="o-grid c-input-holder">
                    <div className="o-grid__item u-2-of-6">
                      <label
                        className="c-label c-label--prefix c-label--md"
                        htmlFor="price-max"
                      >
                        <FormattedMessage id="max" />
                      </label>
                    </div>
                    <div className="o-grid__item u-3-of-6">
                      <input
                        type="number"
                        className="c-input-text c-input-text--md c-input-text--prefix selectOnFocus"
                        id="price-max"
                        name="max_price"
                        value={values.max_price}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--md currency-label">
                        EUR
                      </span>
                    </div>
                  </div>
                  <div className="o-grid c-input-holder">
                    <div className="o-grid__item u-2-of-6">
                      <label
                        className="c-label c-label--prefix c-label--md"
                        htmlFor="price-min"
                      >
                        <FormattedMessage id="min" />
                      </label>
                    </div>
                    <div className="o-grid__item u-3-of-6">
                      <input
                        type="number"
                        className="c-input-text c-input-text--md c-input-text--prefix selectOnFocus"
                        id="price-min"
                        name="min_price"
                        value={values.min_price}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="o-grid__item u-1-of-6">
                      <span className="c-label c-label--postfix c-label--md currency-label">
                        EUR
                      </span>
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="bedrooms" />
                </h2>
                <div className="panel__body">
                  <div className="u-text-center c-input-holder">
                    {[0, 1, 2, 3].map((minRoomCount) => {
                      return (
                        <span
                          className="radio radio--inline"
                          key={minRoomCount}
                        >
                          <label className="c-label">
                            <input
                              type="radio"
                              value={minRoomCount}
                              name="bedrooms"
                              checked={
                                parseInt(values.bedrooms) ===
                                parseInt(minRoomCount)
                              }
                              onChange={handleChange}
                            />
                            {minRoomCount === 3 ? (
                              <FormattedMessage id={'more'} />
                            ) : (
                              minRoomCount
                            )}
                          </label>
                        </span>
                      )
                    })}
                  </div>
                </div>
              </article>
            </div>
            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="minimal-condition" />
                </h2>
                <div className="panel__body">
                  <div className="center-block-contents">
                    <div className="u-text-center conditionHandler">
                      <div className="conditionTitle">
                        <h2 className={`u-mt-alpha text--${values.condition}`}>
                          <FormattedMessage
                            id={`conditions.${values.condition}`}
                          />
                        </h2>
                        <div className="u-mb-gamma">
                          <img
                            src={conditionsImages[values.condition]}
                            style={{ width: 48, height: 48 }}
                          />
                        </div>
                      </div>
                      {config.conditions.map((condition) => {
                        return (
                          <span
                            className={`radio radio--inline radio--${condition}`}
                            key={condition}
                          >
                            <label className="c-label">
                              <input
                                type="radio"
                                name="condition"
                                value={condition}
                                checked={values.condition === condition}
                                onChange={handleChange}
                              />
                              <FormattedMessage
                                id={`conditions.${condition}`}
                              />
                            </label>
                          </span>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </article>
            </div>

            <div className="o-grid__item u-3-of-5-bp3 u-push-1-of-5-bp3">
              <article className="panel">
                <h2 className="panel__title">
                  <FormattedMessage id="wishes.title" />
                </h2>
                <div className="panel__body">
                  <div className="c-input-holder">
                    <div className="o-grid">
                      {config.wishes.map((wish) => {
                        return (
                          <div className="o-grid__item u-1-of-2" key={wish}>
                            <div className="checkbox">
                              <label className="c-label">
                                <input
                                  type="checkbox"
                                  name="wishes"
                                  value={wish}
                                  checked={values.wishes.includes(wish)}
                                  onChange={handleChange}
                                />{' '}
                                <FormattedMessage id={`wishes.${wish}`} />
                              </label>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <div className="c-button-fixed">
        <button
          type="submit"
          className="c-button c-button--md c-button--alpha c-button--block"
        >
          <FormattedMessage id="search" />
        </button>
      </div>
    </form>
  )
}
